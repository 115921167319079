import React, {useRef} from "react";
import {BackgroundWrapper, Column, Container, Header, Rectangle, StyledGlobalWrapper, StyledParagraph, StyledSectionWrapper, Title,} from "./styles";
import {Parallax} from "react-scroll-parallax";
import SellInfo from "components/SellInfo/SellInfo";
import SectionButtons from "./SectionButtons/SectionButtons";
import {useScrollTo} from "shared/Hooks/useScrollTo";
import ImagePdf from "./ImagePdf/ImagePdf";

const Section = ({data, bg, color, reverse, parallax, sectionId}) => {
  const counter = useRef(null);
  let window_width = null;
  const isBrowser = typeof window !== `undefined`;
  const pdfRef = useRef(null);

  if (isBrowser) {
    window_width = window.innerWidth;
  }

  const handleScroll = useScrollTo(pdfRef);

  return (
    <Container bg={bg} color={color} reverse={reverse} ref={pdfRef}>
      {parallax && (
        <Parallax
          y={[50, -100]}
          className="rectangle"
          disabled={window_width < 1101}
        >
          <Rectangle/>
        </Parallax>
      )}
      <StyledGlobalWrapper id={data.linkTag}>
        <StyledSectionWrapper reverse={reverse}>
          <ImagePdf handleScroll={handleScroll} data={data} reverse={reverse} sectionId={sectionId}/>
          <Column ref={counter} className={data.gallery ? "gallery" : ""}>
            <Header>
              <Title>{data.title}</Title>
            </Header>
            <StyledParagraph>{data.subtitle}</StyledParagraph>
            {data.text && <StyledParagraph>{data.text}</StyledParagraph>}
            {data.sellInfo && (
              <SellInfo data={data.sellInfo} counterRef={counter}/>
            )}
            {(data.cta || data.checkOffer || data.links) && (
              <SectionButtons
                cta={data.cta}
                checkOffer={data.checkOffer}
                slug={data.slug}
                links={data.links}
                sectionId={sectionId}
              />
            )}
          </Column>
        </StyledSectionWrapper>
      </StyledGlobalWrapper>

      {data.video && (
        <BackgroundWrapper>
          <video autoPlay muted loop playsInline>
            <source src={data.video} type="video/mp4"/>
          </video>
        </BackgroundWrapper>
      )}
    </Container>
  );
};

export default Section;
