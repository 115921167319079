import styled from "styled-components";
import {AnchorLink} from "gatsby-plugin-anchor-links";

export const Container = styled.div`
    display: flex;
    flex-wrap: wrap;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    width: 45%;

    @media screen and (max-width: ${({theme}) => theme.breakpoints.tablet}) {
        position: relative;
        margin-bottom: 64px;
        width: 100%;
    }
`;

export const ImageWrapper = styled.div`
    width: 100%;
    height: 100%;
`

export const GalleryImg = styled(AnchorLink)`
    width: calc(50% - 6px);
    height: 356px;
    background-color: ${({theme}) => theme.colors.gray_light_bg};
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    filter: grayscale(1);
    transition: filter 0.3s ease, background-color 0.3s;
    cursor: pointer;

    &:hover {
        filter: grayscale(0);
        background-color: ${({theme}) => theme.colors.light_gray};
    }

    &:first-child {
        background-color: ${({theme}) => theme.colors.black};
        filter: grayscale(0);


        &:hover {
            background-color: #191919;
        }
    }

    &:not(:nth-child(2n)) {
        margin-right: 12px;
    }

    .gatsby-image-wrapper {
        width: 100%;
        height: 100%;
    }

    &.small {
        width: calc(25% - 18px);
        height: 100px;
        margin-top: 24px;

        &:not(:nth-child(6n)) {
            margin-right: 24px;
        }
    }

    @media screen and (max-width: ${({theme}) => theme.breakpoints.laptop}) {
        height: 274px;

        &.small {
            height: 78px;
        }
    }

    @media screen and (max-width: ${({theme}) =>
            theme.breakpoints.laptop_small}) {
        height: 238px;

        &.small {
            height: 66px;
        }
    }

    @media screen and (max-width: ${({theme}) =>
            theme.breakpoints.tablet_big}) {
        height: 200px;

        &.small {
            height: 58px;
        }

        &:hover {
            filter: grayscale(1);
            background-color: ${({theme}) => theme.colors.gray_light_bg};
        }

        &:first-child {
            filter: grayscale(1);

            &:hover {
                background-color: ${({theme}) => theme.colors.black};
            }
        }
    }

    @media screen and (max-width: ${({theme}) => theme.breakpoints.tablet}) {
        height: 160px;

        &.small {
            width: calc(25% - 9px);
            height: 46px;

            &:not(:nth-child(6n)) {
                margin-right: 12px;
            }
        }
    }
`;
