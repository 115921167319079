import styled from "styled-components";

export const Row = styled.div`
    width: 100%;
`

export const ButtonsWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 32px;
    width: 100%;

    a {
        margin-right: 0;
    }

    @media screen and (max-width: ${({theme}) => theme.breakpoints.tablet}) {
        margin-top: 24px;
        justify-content: center;

        a {
            justify-content: center;
        }

        button.primary {
            margin-right: 24px;
        }
    }

    @media screen and (max-width: ${({theme}) => theme.breakpoints.phone_big}) {
        font-size: ${({theme}) => theme.fonts.m.fhd};

        button {
            width: 100%;
            padding-top: 12px;

            &.primary {
                margin-right: 0;
            }
        }

    }

    @media screen and (max-width: ${({theme}) =>
            theme.breakpoints.phone_small}) {
        a {
            width: 100%;
        }
    }
`;

export const TypeButton = styled.button`
    background: none;
    border: none;
    outline: none;
    position: relative;
    text-align: left;
    padding: 12px 0;
    width: auto;

    &:hover {
        span::after {
            width: 100%;
        }
    }
`;

export const ButtonWrapper = styled.div`
    width: 50%;

    &:first-child {
        margin-right: 0 !important;
    }

    &:nth-child(odd) {
        padding-right: 24px;
    }

    @media screen and (max-width: ${({theme}) => theme.breakpoints.tablet}) {
        text-align: center;
        button {
            text-align: center;
        }

        &:nth-child(odd) {
            span {
                padding-right: 0px;
            }
        }
    }

    @media screen and (max-width: ${({theme}) => theme.breakpoints.phone_big}) {
        width: 100%;
        &:not(:first-child) {
            margin-top: 12px;
        }

        button {
            padding: 0;
            width: auto;
        }

        &:nth-child(odd) {
            padding-right: 0px;
        }
    }

    @media screen and (max-width: ${({theme}) => theme.breakpoints.phone_big}) and (orientation: landscape) {
        width: 50%;
        margin-bottom: 12px;

        &:not(:first-child) {
            margin-top: 0px;
        }
    }
`

export const TypeButtonText = styled.span`
    font-weight: ${({theme}) => theme.weights.bold};
    font-size: ${({theme}) => theme.fonts.s.fhd};
    color: ${({theme}) => theme.colors.black};
    position: relative;

    &::after {
        content: "";
        position: absolute;
        bottom: -6px;
        left: 0;
        width: 0;
        height: 3px;
        background-color: ${({theme}) => theme.colors.black};
        transition: width 0.3s ease;
    }

    @media screen and (max-width: ${({theme}) =>
            theme.breakpoints.laptop_big}) {
        font-size: ${({theme}) => theme.fonts.s.laptop};
    }

    @media screen and (max-width: ${({theme}) => theme.breakpoints.laptop}) {
        font-size: ${({theme}) => theme.fonts.s.tablet};
    }

    @media screen and (max-width: ${({theme}) => theme.breakpoints.phone_big}) {
        font-size: 12px;
    }
`;
