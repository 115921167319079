import React, {useEffect, useRef, useState} from "react";
import uuid from "react-uuid";
import {Container, List, ListItem, Numbers, PageNumber} from "./styles";
import {PdfButton} from "components/Buttons/Buttons";

const perView = 5;

const PdfBox = ({list, handleScroll}) => {
  const [currentPage, setCurrentPage] = useState(0);
  const [paginatedList, setPaginatedList] = useState([]);
  const lastPage = Math.ceil(list.length / perView);
  const arr = Array.from(Array(lastPage).keys());
  const ref = useRef(null);

  const changePage = (page) => {
    if (currentPage === page) {
      return;
    }

    const startIndex = page * perView;
    const endIndex = Math.min(startIndex + perView, list.length);
    const arr = list.slice(startIndex, endIndex);
    setPaginatedList(arr);
    setCurrentPage(page);
  };

  useEffect(() => {
    setPaginatedList(list.slice(0, perView));
    setCurrentPage(0);
  }, [list]);

  useEffect(() => {
    handleScroll({data: {offset: 10}});
  }, [list, handleScroll]);

  return (
    <Container ref={ref} data-aos="fade-up"     className="disableAosMobile">
      <List>
        {paginatedList.map((pdf) => (
          <ListItem key={uuid()}>
            <PdfButton href={pdf.url} target="_blank">
              {pdf.title}
            </PdfButton>
          </ListItem>
        ))}
      </List>
      <Numbers>
        {arr.map((page, i) => (
          <PageNumber
            title=""
            key={uuid()}
            className={currentPage === i ? "active" : ""}
            onClick={() => changePage(i)}
          >
            {page + 1}
          </PageNumber>
        ))}
      </Numbers>
    </Container>
  );
};

export default PdfBox;
