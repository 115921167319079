import React from 'react';
import {Column, ImageWrapper} from "../styles";
import Gallery from "../../Gallery/Gallery";
import Image from 'gatsby-image';
import {useSelector} from "react-redux";
import PdfBox from "../../PdfBox/PdfBox";

const ImagePdf = ({data, handleScroll, reverse, sectionId}) => {

  const productId = useSelector(state => state.modals.pdfs[sectionId]);

  return (
    <Column reverse={reverse}>
      {data.src && !productId && (
        <ImageWrapper
          className={data.bigImg ? "big disableAosMobile" : "disableAosMobile"}
          reverse={reverse}
          data-aos="fade"
        >
          <Image
            fluid={data.src.childImageSharp.fluid}
            alt={data.title}
          />
        </ImageWrapper>
      )}
      {productId && (
        <PdfBox list={data.links[productId].linkedCatalogues} handleScroll={handleScroll}/>
      )}
      {data.gallery && <Gallery images={data.gallery}/>}
    </Column>
  );
}

export default ImagePdf;
