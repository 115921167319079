import React from "react";
import {Container, GalleryImg, ImageWrapper} from "./styles";
import uuid from "react-uuid";
import Image from "gatsby-image";

const Gallery = ({images}) => {
  return (
    <Container>
      {images.map((img, index) => (
        <GalleryImg
          key={uuid()}
          className={img.big ? "" : "small"}
          to={img.slug}
          title={img.alt}
        >
          <ImageWrapper data-aos="fade" data-aos-delay={`${index * 100}`} data-aos-anchor-placement="top-bottom" className="disableAosMobile">
            <Image fluid={img.src.childImageSharp.fluid} alt={img.alt}/>
          </ImageWrapper>
        </GalleryImg>
      ))}
    </Container>
  );
};

export default Gallery;
