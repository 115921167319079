import styled from "styled-components";
import {Paragraph} from "components/Typography/Typography";

export const Container = styled.div`
    display: flex;
    align-items: center;
    margin-top: 36px;
`;

export const SellIcon = styled.div`
    width: 62px;
    height: 62px;
    margin-right: 32px;

    @media screen and (max-width: ${({theme}) =>
            theme.breakpoints.laptop_big}) {
        width: 52px;
        height: 52px;
    }

    @media screen and (max-width: ${({theme}) => theme.breakpoints.laptop}) {
        width: 48px;
        height: 48px;
    }

    @media screen and (max-width: ${({theme}) =>
            theme.breakpoints.laptop_small}) {
        width: 42px;
        height: 42px;
    }

    @media screen and (max-width: ${({theme}) =>
            theme.breakpoints.tablet_big}) {
        width: 36px;
        height: 36px;
    }

    @media screen and (max-width: ${({theme}) =>
            theme.breakpoints.tablet}) {
        margin-right: 20px;
    }
`;

export const Icon = styled.img`
    max-width: 100%;
    max-height: 100%;
    width: 100%;
    height: 100%;
`;

export const SellText = styled.span`
    font-size: ${({theme}) => theme.fonts.xl.laptop_small};
    font-weight: ${({theme}) => theme.weights.bold};
    border-left: 1px solid ${({theme}) => theme.colors.gray};
    padding: 0 32px;

    @media screen and (max-width: ${({theme}) =>
            theme.breakpoints.tablet_big}) {
        font-size: ${({theme}) => theme.fonts.xl.tablet_big};
    }

    @media screen and (max-width: ${({theme}) => theme.breakpoints.tablet}) {
        font-size: ${({theme}) => theme.fonts.xl.tablet};
        padding: 0 20px;
    }
`;

export const SellTitle = styled(Paragraph)`
    font-size: ${({theme}) => theme.fonts.s.laptop};
    font-weight: ${({theme}) => theme.weights.bold};

    @media screen and (max-width: ${({theme}) =>
            theme.breakpoints.tablet_big}) {
        font-size: ${({theme}) => theme.fonts.s.tablet};
    }
`;
