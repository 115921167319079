import React from "react";
import {ButtonsWrapper, ButtonWrapper, Row, TypeButton, TypeButtonText} from "./styles";
import {Button, PdfButton} from "components/Buttons/Buttons";
import uuid from "react-uuid";
import {useDispatch} from "react-redux";
import {formModalToggle, pdfModalToggle} from "../../../store/modals";

const SectionButtons = ({cta, checkOffer, slug, links, sectionId}) => {
  const dispatch = useDispatch();

  const openPdfModal = (id) => {
    dispatch(pdfModalToggle(id, sectionId))
  };

  const handleFormModalOpen = () => {
    dispatch(formModalToggle());
  }

  const renderButton = (link, id) => {
    if (link.linkedCatalogues) {
      return (
        <ButtonWrapper key={uuid()}>
          <TypeButton onClick={() => openPdfModal(id)} data-aos="fade-up" className="disableAosMobile">
            <TypeButtonText>{link.text}</TypeButtonText>
          </TypeButton>
        </ButtonWrapper>
      );
    }
    return (
      <Row key={uuid()}>
        <PdfButton href={link.url} data-aos="fade-up" className="disableAosMobile">
          {link.text}
        </PdfButton>
      </Row>
    );
  };

  return (
    <ButtonsWrapper
    >
      {cta && (
        <Button onClick={handleFormModalOpen} data-aos="fade-up" className="disableAosMobile primary">
          {cta}
        </Button>
      )}
      {checkOffer && (
        <Button to={slug} className="primary disableAosMobile" data-aos="fade-up">
          {checkOffer}
        </Button>
      )}
      {links && <>{links.map((link, i) => renderButton(link, i))}</>}
    </ButtonsWrapper>
  );
};

export default SectionButtons;
