import React, {useEffect, useState} from "react";
import {Container, Icon, SellIcon, SellText, SellTitle} from "./styles";
import CountUp from "react-countup";
import useOnScreenOnce from "shared/Hooks/useOnScreenOnce";

const SellInfo = ({data, counterRef}) => {
  const onScreen = useOnScreenOnce(counterRef);
  const [count, setCount] = useState(23212);

  useEffect(() => {
    const initialDate = new Date(2021, 4, 31);
    const dateNow = Date.now();
    const diff = Math.abs(dateNow - initialDate) / 36e5;
    const newCount = count + diff * 2;
    setCount(newCount);
  }, [])

  return (
    <Container
      data-aos="fade-up"
      className="disableAosMobile"
    >
      <SellIcon>
        <Icon src={data.icon} alt=""/>
      </SellIcon>
      <SellText>
        {onScreen ? (
          <CountUp
            start={0}
            end={count}
            duration={6}
            redraw={true}
          />
        ) : null}
      </SellText>
      <SellTitle>{data.caption}</SellTitle>
    </Container>
  );
};

export default SellInfo;
