import styled from "styled-components";

export const Container = styled.div`
    box-shadow: 10px 12px 25px -9px rgb(0 0 0 / 7%);
    display: flex;
    width: 100%;
    flex-direction: column;
    min-height: 450px;
    justify-content: space-between;
    padding: 24px 24px 24px 0;
    position: relative;


    @media screen and (max-width: ${({theme}) => theme.breakpoints.laptop}) {
        min-height: 380px;
    }

    @media screen and (max-width: ${({theme}) => theme.breakpoints.laptop_small}) {
        min-height: 350px;
    }
    
    @media screen and (max-width: ${({theme}) => theme.breakpoints.tablet}) {
        margin-bottom: 36px;
        padding: 24px 24px;
        min-height: 330px;
    }

    @media screen and (max-width: ${({theme}) => theme.breakpoints.phone_big}) and (orientation: portrait) {
        min-height: 506px;
    }
`;

export const List = styled.ul`
    text-align: left;
`;
export const ListItem = styled.li`
    color: ${({theme}) => theme.colors.black};

    span {
        font-size: 14px;
    }

    @media screen and (max-width: ${({theme}) => theme.breakpoints.laptop}) {
        span {
            font-size: 12px;
        }
    }

    @media screen and (max-width: ${({theme}) => theme.breakpoints.phone_big}) {
        a {
            width: 100%;
        }
    }
`;

export const Numbers = styled.div`
    display: flex;
    justify-content: center;
`;

export const PageNumber = styled.span`
    color: ${({theme}) => theme.colors.gray};
    cursor: pointer;

    &:not(:last-child) {
        margin-right: 24px;
    }

    &.active {
        color: ${({theme}) => theme.colors.black};
    }
`;
